<template>
  <div class="flex-end" :class="{'mobile':isMobile,'dark':theme}">
    <div>
      <div class="mask" v-if="showLeft" @click="showLeft = false"></div>
      <!-- left start -->
      <Left :class="!showLeft&isMobile ? 'noWid' : ''" @changeTheme="changeTheme"/>
      <!-- left end -->
      <div class="mobile-header" v-if="isMobile">
        <div class="flex-between">
          <div class="mobile-logo flex-between">
            <span></span>
          </div>
          <div class="mobile-category" @click="showLeft = true"></div>
        </div>
      </div>
    </div>
    <div class="main right-container">
      <div class="main-content">
          <div      
      class="menu-notice menu-notice-orange"
      v-if="isMobile"
    >
      <h2>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGqADAAQAAAABAAAAGgAAAABMybYKAAACV0lEQVRIDa1WMU8UQRR+b8DOxALimUiQAhJoJYFQGEkUEivwPwhYgO0lQCkkR6sWF+2NFaGV5hISE020hRLlCiXQaSV3z+/NMnuz7szt4jnJZt9+73vf29mdeW+YCoZsj4yTXCyQ0DwxDZPILRvC/B3YN2Dvifv3eP34qJsUx5zyfGgKIjsQvh/jZHFu4LnKm81PWTx5yiWS+uQ1Ojt9QdJeCQUUYmzqNHhzjVc+//a5mUSyPT5A8nMXs7jnk65sMx8QX3/M60fnLtY4w87kfyRRQX1RaFnNywRpIjr78bJwJkwtYq4lF+xuQ5PpL7gc9tPZH0/y0YHRO/Mb3mguqV+2hl7jxZ5EuamDp3WBJDPS1VVuND2ab3twzqwpYqR2ZwJvVnIJ50RKADKre9FQq7VQgt0j5WLRUJvmelQpDkcOY8tKMbU3BkqXwf9JaldvUt2jkaOzj7pTndevJL7t/NE7Ph2qcPkx5VF924MDJnL021JPNBpw5yGRR7J1+4N1iMzkCREE7cSQof2IOwwLzeDlyidRFfQsQ319e2HFAMpIwfwKl9awdoARhtAYDVe/HiJlI8zIoe9Q61ZxPUPM25w3CHBDu69bddUgJwdypQOJZ3fQgGW10yWKalzHnloOELMQ0xfM5he4xc0R3ZY3Tp6qgJsR0WBlFd/+IKsaeBK6Wy4JtNDSnUKayPZ4tN9SyVx07O5auXduSBNpjO3xYzcekh4w/nXYw0nlgX9esNoxvaTrEpqWzMY4WfyKx61sMNJoY9Sepe0kdIDUDY+9mGyTv6M7z38Af0O7OzFnfV8AAAAASUVORK5CYII="
        />请链接到HECO Mainnet
      </h2>
      <!---->
      <!---->
      <!---->
      <!---->
      <!---->
      <!---->
      <!---->
    </div>
        <!-- start -->
        <router-view></router-view>
        <!-- end -->
      </div>
      <!-- foot start-->
      <Footer />
      <!-- foot end -->
    </div>
  </div>
</template>
<script>
import Left from "../components/Left.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    Left,
    Footer,
  },
  data() {
    return {
      isMobile: false,
      showLeft: false,
      theme: true
    };
  },
  mounted() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
      changeTheme(data) {
          console.log(data)
          this.theme = data
      }
  }
};
</script>

<style>
.layout {
  background: #f0f2f5;
  /* padding: 30px 0 44px; */
  padding: 0;
  transition: all 0.3s;
  overflow: auto;
}
</style>
