<template>
  <div class="footer-container">
        <div class="footer-link">
          审计机构:
          <a
            href="https://www.lendhub.org/report/LendHubreport-020821.pdf"
            target="FairProof"
            class="link-logo"
            ><img :src="require('../assets/img/bi/fairproof.7226619.png')"
          /></a>
          <a
            href="https://www.lendhub.org/report/LendHubAuditReport.pdf"
            target="零时科技"
            class="link-logo"
            ><img
              :src="require('../assets/img/bi/lingshi-logo@2x.e86fc7d.png')"
          /></a>
          <a
            href="https://www.lendhub.org/report/LendHubAuditReport2.pdf"
            target="知道创宇"
            class="link-logo"
            ><img :src="require('../assets/img/bi/zhidao-logo@2x.bfbb9d1.png')"
          /></a>
        </div>
        <p>©2021 Kangaroo.org</p>
      </div>
</template>

<style>


</style>