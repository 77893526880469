import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '借贷市场'
                },
                component: () => import (/* webpackChunkName: "dashboard" */"../views/Dashboard.vue")
            },
            {
                path: "/mining",
                name: "mining",
                meta: {
                    title: '质押挖矿'
                },
                component: () => import (/* webpackChunkName: "mining" */"../views/mining.vue")
            },
            {
                path: "/dao",
                name: "dao",
                meta: {
                    title: 'DAO'
                },
                component: () => import (/* webpackChunkName: "dao" */"../views/dao.vue")
            },
            {
                path: "/governance",
                name: "governance",
                meta: {
                    title: '投票治理'
                },
                component: () => import (/* webpackChunkName: "governance" */"../views/governance.vue")
            },
             {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (/* webpackChunkName: "404" */
                '../views/404.vue')
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Login.vue")
    },
    {
        path: "/register",
        name: "register",
        meta: {
            title: '注册'
        },
        component: () => import (
        /* webpackChunkName: "register" */
        "../views/register.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    // const role = localStorage.getItem('user_info');
    // if (!role && to.path !== '/login' && to.path !== '/register') {
    //     next('/login');
    // } else if (to.meta.permission) {
    //     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    //     role === 'admin'
    //         ? next()
    //         : next('/403');
    // } else {
        next();
    // }
});

export default router;