<template>
    <div class="header-container header" >
        <div class="menu-scroll-frame">
          <div class="menu-notice menu-notice-orange" v-if="!isMobile">
            <!-- <h2>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGqADAAQAAAABAAAAGgAAAABMybYKAAACV0lEQVRIDa1WMU8UQRR+b8DOxALimUiQAhJoJYFQGEkUEivwPwhYgO0lQCkkR6sWF+2NFaGV5hISE020hRLlCiXQaSV3z+/NMnuz7szt4jnJZt9+73vf29mdeW+YCoZsj4yTXCyQ0DwxDZPILRvC/B3YN2Dvifv3eP34qJsUx5zyfGgKIjsQvh/jZHFu4LnKm81PWTx5yiWS+uQ1Ojt9QdJeCQUUYmzqNHhzjVc+//a5mUSyPT5A8nMXs7jnk65sMx8QX3/M60fnLtY4w87kfyRRQX1RaFnNywRpIjr78bJwJkwtYq4lF+xuQ5PpL7gc9tPZH0/y0YHRO/Mb3mguqV+2hl7jxZ5EuamDp3WBJDPS1VVuND2ab3twzqwpYqR2ZwJvVnIJ50RKADKre9FQq7VQgt0j5WLRUJvmelQpDkcOY8tKMbU3BkqXwf9JaldvUt2jkaOzj7pTndevJL7t/NE7Ph2qcPkx5VF924MDJnL021JPNBpw5yGRR7J1+4N1iMzkCREE7cSQof2IOwwLzeDlyidRFfQsQ319e2HFAMpIwfwKl9awdoARhtAYDVe/HiJlI8zIoe9Q61ZxPUPM25w3CHBDu69bddUgJwdypQOJZ3fQgGW10yWKalzHnloOELMQ0xfM5he4xc0R3ZY3Tp6qgJsR0WBlFd/+IKsaeBK6Wy4JtNDSnUKayPZ4tN9SyVx07O5auXduSBNpjO3xYzcekh4w/nXYw0nlgX9esNoxvaTrEpqWzMY4WfyKx61sMNJoY9Sepe0kdIDUDY+9mGyTv6M7z38Af0O7OzFnfV8AAAAASUVORK5CYII="
              />请链接到HECO Mainnet
            </h2> -->
            <!---->
            <!---->
            <!---->
            <!---->
            <!---->
            <!---->
            <!---->
          </div>
          <div class="menu-scroll">
            <div class="logos header-logos logo">
              <img :src="require('../assets/img/bi/logo.png')" />
            </div>
            <div class="menu-content">
              <div class="features">
                <div class="connect-wallet">
                  <div class="account-basic-info">
                    <div class="address-text pointer">
                      <img
                        :src="
                          require('../assets/img/bi/walletSuccess.abb8324e.png')
                        "
                        alt=""
                      /><span class="addr-span">...</span>
                    </div>
                    <!---->
                  </div>
                  <div class="lhb">
                    <div class="amount">钱包余额：0.0000 ROO</div>
                    <br />
                    <div class="amount">待取收益：0.0000 ROO</div>
                    <br />
                    <div class="withdraw">提取收益挖矿</div>
                    <div class="el-dialog__wrapper" style="display: none">
                      <div
                        aria-modal="true"
                        aria-label="ROO"
                        class="el-dialog ant-modal dialog-standard-lhb dark"
                        style="margin-top: 15vh; width: 400px"
                      >
                        <div class="el-dialog__header">
                          <div class="el-dialog__header-slot">
                            <!-- <img :src="require('../assets/img/bi/lhb.png')" /> -->
                            ROO
                          </div>
                          <button
                            type="button"
                            aria-label="Close"
                            class="el-dialog__headerbtn"
                          >
                            <i
                              class="el-dialog__close el-icon el-icon-close"
                            ></i>
                          </button>
                        </div>
                        <!----><!---->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="horizontal-line menu"></div>
                <!---->
                <!----><!----><!---->
              </div>
              <div class="menu-links">
                <ul>
                  <li>
                    <router-link to="/dashboard"><i class="icon-logo icon-logo-market"></i>借贷市场</router-link>
                  </li>
                  <li>
                    <router-link to="/mining"><i class="icon-logo icon-logo-mining"></i>质押挖矿</router-link>
                  </li>
                  <li>
                    <router-link to="/dao"><i class="icon-logo icon-logo-governance"></i>DAO</router-link>
                  </li>
                  <li>
                    <router-link to="/governance"><i class="icon-logo icon-logo-dao"></i>投票治理</router-link>
                  </li>
                </ul>
              </div>
              <div class="horizontal-line menu"></div>
              <div class="links new-links">
                <a
                  href="https://www.yuque.com/lendhub/kb"
                  target="report"
                  class="logos small-logo shuoming-logo"
                  ><span></span><span>产品说明</span></a
                >
                <a
                  href="https://www.yuque.com/lendhub/thcoc4"
                  target="report"
                  class="logos small-logo shuoming-logo"
                  style="display: none"
                  ><span></span><span>产品说明</span></a
                >
                <a
                  href="https://www.yuque.com/lendhub/kb/gzvbof"
                  target="report"
                  class="logos small-logo mining-logo"
                  ><span></span><span>挖矿教程</span></a
                >
               
                <a href="javascript:;" class="logos small-logo " :class="theme? 'theme-logo_light':'theme-logo_dark'" @click="changeTheme()"
                  ><span></span><span>{{theme? '浅色模式':'深色模式'}}</span></a
                >
                <a href="javascript:;" class="logos small-logo lang-logo_lang"
                  ><span></span><span>语言切换</span>
                  <i class="el-icon-caret-bottom el-icon-lang"></i>
                  <i
                    class="el-icon-caret-top el-icon-lang"
                    style="display: none"
                  ></i
                ></a>
                <div class="aside-lang-list" style="display: none">
                  <a href="javascript:;">中文简体</a>
                  <a href="javascript:;">English</a>
                  <a href="javascript:;">日本語</a>
                </div>
              </div>
              <div class="links"><!----></div>
            </div>
          </div>
        </div>
        <div class="menu-footer menu-footer-mini">
          <a
            href="https://twitter.com/LendHubDefi"
            target="twitter"
            class="logos mini-logo twitter-logo"
            ><span></span><span>Twitter</span></a
          >
          <a
            href="https://t.me/Lendhub"
            target="telegram"
            class="logos mini-logo telegram-logo"
            ><span></span><span>Telegram</span></a
          ><a
            href="https://github.com/lendhub/lendhub "
            target="github"
            class="logos mini-logo github-logo"
            ><span></span><span>Github</span></a
          >
          <a href="javascript:;" class="logos mini-logo wechat-logo"
            ><span></span><span>Wechat</span></a
          >
          <div class="pop-weixin pop-weixin-mini" style="display: none">
            <img src="/_nuxt/img/wechat.4229b99.jpeg" />
          </div>
          <!---->
        </div>
        <div class="el-dialog__wrapper" style="display: none">
          <div
            aria-modal="true"
            aria-label="Connect Wallet"
            class="el-dialog login-modal custom-modal"
            style="margin-top: 15vh; width: 30%"
          >
            <div class="el-dialog__header">
              <span class="el-dialog__title">Connect Wallet</span
              ><button
                type="button"
                aria-label="Close"
                class="el-dialog__headerbtn"
              >
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </button>
            </div>
            <!----><!---->
          </div>
        </div>
      </div>
</template>

<script>
export default {
  data() {
    return {
        kefu: '',
        theme: true, // true 深色 false 浅色
    }
  },
  mounted() {
    
  },
  methods: {
    changeTheme(){
      this.theme = !this.theme
      this.$emit('changeTheme', this.theme)
    }
  }
}
</script>

<style lang="scss" scoped>

.menu-footer-mini .logos {
    overflow: visible;
    display: flex;
    float: left;
    width: auto;
    padding-left: 10px!important
}

.menu-footer-mini .logos:after {
    display: block;
    clear: both;
    content: " ";
    visibility: hidden;
    font-size: 0;
    height: 0
}

.menu-footer-mini .logos>span:first-child {
    width: 18px;
    height: 18px
}

.menu-footer-mini .logos>span:last-child {
    display: none
}
</style>